@import "../../../styles/variable.module.scss";
.accordion {
  margin-bottom: 32px;
  @include mobile_device {
    margin-bottom: 20px;
  }
  [class*="card-body"] {
    margin-top: 32px;
    @include medium_device {
      margin-top: 20px;
    }
  }
  [class*="card-header"] {
    padding: 0 0 22px;
    border-bottom: 1px solid var(--border-color);
    [class*="row"] {
      .selectMenu {
        min-width: 190px;
        margin-right: 12px;
        @include large_device {
          min-width: 220px;
        }
        @include mobile_device {
          margin-right: 0;
          margin-bottom: 15px;
        }
        [class*="select__Inner__menu"] {
          min-width: 200px;
        }
      }
      button {
        @include large_device {
          width: 100%;
        }
      }
    }
  }
  [class*="value-container"],
  [class*="__option"] {
    display: flex;
    align-items: center;
    span {
      margin-left: 8px;
    }
    input{
      left: inherit !important;
    }
  }
  .filterBtn {
    min-width: 116px;
    padding: 0 20px;
    @include medium_device {
      width: 100%;
    }
    svg {
      width: 14px;
      margin-left: 10px;
      flex: 0 0 auto;
    }
  }
  .AddedselectMenu {
    width: 100%;
  }
  .imgs{
    height: 20px;
    width: 20px;
    flex: 0 0 auto;
  }
}

.inputGroup {
  [class*="form-control"] {
    background: var(--background-color);
    text-transform: capitalize;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    border-radius: 12px !important;
  }
  span {
    color: var(--variantColor);
    margin: 0 8px;
    text-align: center;
    text-transform: capitalize;
    font-weight: 500;
  }
  [class$="selectMenu"] {
    [class*="--menu-is-open"] {
      [class^="select__Inner__indicator"] {
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }
    [class^="select__Inner__indicator"] {
      -webkit-box-shadow: none;
      box-shadow: none;
      width: auto;
    }
  }
}
.rangeSlider {
  & > [class*="btn"] {
    padding: 0;
    background-color: transparent !important;
    border: none;
    &:hover {
      background-color: transparent !important;
    }
  }
  [class*="dropdown-menu"] {
    padding: 15px;
    min-width: 100%;
    max-width: 100%;
    margin-top: 5px;
    border: 1px solid var(--box-color);
    @media screen and (min-width: 768px) and (max-width: 991px) {
      min-width: 220px;
      right: 0 !important;
      left: auto !important;
    }
  }
  .inputGroup {
    .btns {
      button {
        & + button {
          margin-left: 10px;
        }
      }
    }
  }
}
[dir="rtl"] {
  .arPdding{
    @media only screen and (min-width: 1400px){
      padding-left: 50px;
    }
  }
  .accordion {
    [class*="card-header"] {
      [class*="row"] {
        .selectMenu {
          margin-left: 12px;
          margin-right: 0;
          @include mobile_device {
            margin-left: 0;
            margin-bottom: 15px;
          }
        }
      }
    }
    [class*="value-container"],
    [class*="__option"] {
      span {
        margin-left: 0;
        margin-right: 8px;
      }
    }
    .filterBtn {
      svg {
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }
  .inputGroup {
    [class*="group-text"] {
      right: 16px;
      left: auto;
    }
    [class*="form-control"] {
      padding: 0 32px 0 16px;
    }
  }
  .rangeSlider { 
    .inputGroup {
      .btns {
        button {
          & + button {
            margin-right: 10px;
            margin-left: 0;
          }
        }
      }
    }
    [class*=dropdown-menu]{
      @media screen and (min-width: 768px) and (max-width: 991px) {
        left: 0 !important;
        right: auto !important;
      }
    }
  }
}
